.signup__bg {
    background-image: url(https://images.unsplash.com/photo-1519066446840-04a14668e933?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80);
    height: 92vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.signup-profile-pic__container {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: relative;
}

.signup-profile-pic {
    width: 100px;
    border-radius: 50%;
    border: 2px solid gray;
    object-fit: cover;
    height: 100px;
}

.add-picture-icon {
    position: absolute;
    bottom: 0;
    right: 10px;
    color: green;
    background: white;
    cursor: pointer;
    z-index: 99;
}
