.home__bg {
    background-image: url(https://images.unsplash.com/photo-1607748862156-7c548e7e98f4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80);
    height: 92vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.home-message-icon {
    margin-left: 10px;
    display: inline-block;
}
